import * as React from 'react';
import { HashRouter } from 'react-router-dom';
import { Appbar } from '@samc/workbench-core';
import { useUser } from '@samc/single-spa-authentication';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import { BrowserHistory, HashHistory } from 'history';
import { LayerWrapper } from '@samc/react-ui-core';
import { HistoryProvider } from '@samc/react-ui-history';
import { defaultTheme, ThemeProvider } from '@samc/react-ui-theme';

interface Props {
    styletronEngine: Styletron;
    browserHistory: BrowserHistory;
    hashHistory: HashHistory;
}

const placeholder = (): void => undefined;
const Root = (): React.ReactElement => {
    const user = useUser();

    return (
        <Appbar
            onHamburgerHoverModeChange={placeholder}
            user={{
                firstName: user?.given_name || '',
                lastName: user?.family_name || '',
                email: user?.email || '',
            }}
        />
    );
};

const Wrapper = (props: Props): React.ReactElement => {
    const { styletronEngine, hashHistory, browserHistory } = props;

    return (
        <ThemeProvider value={defaultTheme}>
            <LayerWrapper>
                <HistoryProvider value={{ browserHistory, hashHistory }}>
                    <StyletronProvider value={styletronEngine} debugAfterHydration>
                        <HashRouter>
                            <Root />
                        </HashRouter>
                    </StyletronProvider>
                </HistoryProvider>
            </LayerWrapper>
        </ThemeProvider>
    );
};

export default Wrapper;
