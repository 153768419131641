var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { saveWorkbench } from '../api/Requests';
import useMutation from '../useMutation/useMutation';
import { WORKBENCH_KEY } from '../useWorkbench/useWorkbench';
import { WorkbenchContext } from '../WorkbenchContext';
export const useWorkbenchSaver = (workbench) => {
    var _a;
    const endpoint = React.useContext(WorkbenchContext);
    const [saveWorkbenchReq, saveWorkbenchMutation] = useMutation(saveWorkbench(endpoint.WorkbenchApi), [WORKBENCH_KEY, (_a = workbench.id) !== null && _a !== void 0 ? _a : '']);
    return [
        (wb) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const res = yield saveWorkbenchReq(wb);
                endpoint.ShowMessage(`Workbench saved successfully`);
                return res;
            }
            catch (err) {
                endpoint.ShowMessage(`Failed to save Workbench`);
                return wb;
            }
        }),
        saveWorkbenchMutation,
    ];
};
export default useWorkbenchSaver;
