import * as React from 'react';
import { SitusStyledAppbar as RUIAppbar } from '@samc/react-ui-core/lib/atoms/Appbar/SitusStyledAppbar/SitusStyledAppbar';
import { useHistoryContext } from '@samc/react-ui-history';
import { Button } from '@samc/react-ui-core';
import { useStyletron } from 'styletron-react';
export const Appbar = (props) => {
    const { user, children } = props;
    const { browserHistory } = useHistoryContext();
    const [css] = useStyletron();
    const userDropdownContents = React.useMemo(() => {
        const onClick = () => browserHistory.push('/logout');
        return (React.createElement("div", { style: { margin: '5px' } },
            React.createElement(Button, { onClick: onClick, text: "Log Out", type: "button", buttonType: "light" })));
    }, [browserHistory]);
    return (React.createElement(RUIAppbar, { hideWorkbenchButton: true, userName: `${user.firstName} ${user.lastName}`, userDropdownContents: userDropdownContents, additionalControls: React.createElement("div", { className: css({ marginLeft: 'auto' }) }, children) }));
};
export default Appbar;
